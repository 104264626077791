import React from "react";
import CallingDigipreneurComp from "../../Components/CallingDigipreneur"
import Testimoni from "../../Components/Testimoni";

const CallingDigipreneur = () => {
        return (
                <>
                        <main>
                                <CallingDigipreneurComp />
                                {/* <Testimoni /> */}
                        </main>
                </>
        );
};

export default CallingDigipreneur;
