import React, { useEffect } from "react";
import "./landing.css";
import { TypeAnimation } from "react-type-animation";
import Counting from './counting';
import Client from './client';
import ReviewPage from './review';
import { gsap } from "gsap";
import Hexagon from "../../hexagon/hexagon zeals-07.png"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const Content = () => {  
        const animateFadeIn = (element, direction) => {
                let animationProps = { opacity: 0 };
                if (direction === 'left') {
                        animationProps.x = 60;
                } else {
                        animationProps.x = -60;
                }

                gsap.fromTo(
                        element,
                        animationProps,
                        { opacity: 1, x: 0, y: 0, duration: 1, ease: 'power1.out' }
                );
        };
        console.log("TEST : ", process.env.PUBLIC_URL)
        useEffect(() => {
                const columns = document.querySelectorAll('.fade-in-column');
                columns.forEach((col, index) => {
                        let direction = 'left';
                        if (index % 2 === 0) {
                                direction = 'right';
                        }
                        animateFadeIn(col, direction);
                });
        }, []);
        const { t } = useTranslation();
        return (  
                <>
                        <section className="pb-first section-landing">
                                <article>
                                        <div className="container">
                                                <div className="row margin-top mb-5">
                                                        <div className="col-md-12 col-lg-6 col-sm-12 fade-in-column d-flex align-items-center">
                                                                <div>
                                                                        <h1 className="fs-1 text-color-blue">{t('content.landing.maximize')}</h1>
                                                                        <TypeAnimation
                                                                                sequence={[
                                                                                        "Influence Connect",
                                                                                        2000,
                                                                                        "Academy",
                                                                                        2000,
                                                                                        "Awarding",
                                                                                        2000,
                                                                                ]}
                                                                                wrapper="h5"
                                                                                className="mt-2 fs-1 typing"
                                                                                cursor={true}
                                                                                repeat={Infinity}
                                                                        />
                                                                        <p className="text-muted sub-title fs-5 mb-4 mt-3">{t('content.landing.maxicontent')}</p>
                                                                        <div className="d-flex join-group-button">
                                                                                <Link to={`/influence-connect`}>
                                                                                        <button type="button" class="btn btn-landing rounded-pill px-5 py-3 me-2  fw-semibold fs-5">{t('content.landing.buttonOne')}</button>
                                                                                </Link>
                                                                                <Link to={`/calling-all-digipreneur`}>
                                                                                        <button type="button" class="btn btn-landing rounded-pill px-5 py-3 ms-2 fw-semibold fs-5">{t('content.landing.buttonTwo')}</button>
                                                                                </Link>
                                                                        </div>
                                                                </div>
                                                                
                                                        </div>
                                                        <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center fade-in-column">
                                                                <img src={Hexagon} alt="hexagon logo" className="hexagon-logo"/>
                                                        </div>
                                                </div>
                                        </div>
                                </article>
                                <Counting />  
                        </section>
                        <section className="margin-top">
                                <div className="container">
                                        <Client />      
                                </div>
                        </section>
                        <section className="margin-top blue-bg">
                                <ReviewPage />
                        </section>
                </>
        );
};

export default Content;
