// import axios from "axios";
import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../data.json";
import Form from "../../Components/Form";
import Testimoni from "../../Components/Testimoni";

const InfluenceConnect = () => {
        const location = useLocation();

        let language = [];
        language = Data[location.hash];

        if (location.hash === "") {
                language = Data["#en"];
        }

        return (
                <>
                        <main>
                                <Form />
                                {/* <Testimoni /> */}
                        </main>
                </>
        );
};

export default InfluenceConnect;
