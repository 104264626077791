import React, { useEffect } from "react";
import "./calling.css";
import { gsap } from "gsap";
import Second from "../../digipreneur/second.png"
import Forth from "../../digipreneur/forth.png"
import { useTranslation } from 'react-i18next';
import Header from '../../digipreneur/header.png'

const CallingDigipreneurComp = () => {  
        const animateFadeIn = (element, direction) => {
                let animationProps = { opacity: 0 };
                if (direction === 'left') {
                        animationProps.x = 60;
                } else {
                        animationProps.x = -60;
                }

                gsap.fromTo(
                        element,
                        animationProps,
                        { opacity: 1, x: 0, y: 0, duration: 1, ease: 'power1.out' }
                );
        };

        useEffect(() => {
                const columns = document.querySelectorAll('.fade-in-column');
                columns.forEach((col, index) => {
                        let direction = 'left';
                        if (index % 2 === 0) {
                                direction = 'right';
                        }
                        animateFadeIn(col, direction);
                });
        }, []);

        const { t } = useTranslation();
        const howToBecame = t('howToBecame', { returnObjects: true });
        return (  
                <>
                        <section className="pb-first text-color-blue">
                                <article>
                                        <div className="container">
                                                <div className="row margin-top margin-bottom">
                                                        <div className="col-md-12 col-lg-6 col-sm-12 d-flex align-items-center fade-in-column">
                                                                <h1 className="fs-1 fw-semibold">{t('callingInfluencer.titleHeader')}</h1>
                                                        </div>
                                                        <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center fade-in-column ">
                                                                <img src={Header} alt="hexagon logo" className="hexagon-logo"/>
                                                                {/* <video
                                                                        autoPlay
                                                                        controls
                                                                        width="50%"
                                                                        height="auto"
                                                                        className="rounded-4 shadow-box"
                                                                >
                                                                        <source src={require('../../tiktok.mp4')} type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                </video> */}
                                                        </div>
                                                </div>

                                        </div>
                                </article>
                        </section>
                        <section>
                                <div className="container text-color-blue margin-top margin-bottom">
                                        <h4 className="fw-bold text-center fs-3 mt-4">{t('callingInfluencer.titleParagraph')}</h4>
                                        <p className="fs-5 text-center">{t('callingInfluencer.paragraph')}</p> 
                                </div>
                        </section>
                        <section>
                                <div className="container text-color-blue margin-top margin-bottom">
                                        <div className="row">
                                                <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
                                                        <img src={Second} alt="hexagon logo" className="hexagon-logo"/>
                                                </div>
                                                <div className="col-md-12 col-lg-6 col-sm-12 d-flex align-items-center">
                                                        <div>
                                                                <h1 className="fs-3  fw-semibold">{t('callingInfluencer.whatIs')}</h1>
                                                                <p className="mt-3 fs-5">{t('callingInfluencer.explain')}</p>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </section>
                        
                        <section>
                                <div className="container text-color-blue">
                                        <div className="row margin-top margin-bottom">
                                                <div className="col-md-12 col-lg-6 col-sm-12 d-flex align-items-center">
                                                        <div>
                                                                <h1 className="fs-3  fw-semibold">{t('callingInfluencer.intuitive')}</h1>
                                                                <p className="mt-3 fs-5">{t('callingInfluencer.intutiveDesc')}</p>
                                                        </div>
                                                        
                                                </div>
                                                <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
                                                        <img src={Forth} alt="hexagon logo" className="forth-logo"/>
                                                </div>
                                        </div>
                                </div>
                        </section>

                        <section className="blue-color-bg">
                                <div className="container text-color-blue">
                                        <div className="row margin-top margin-bottom">
                                                <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
                                                        <video
                                                                autoPlay
                                                                controls
                                                                width="50%"
                                                                height="auto"
                                                                className="rounded-4 shadow-box"
                                                        >
                                                        <source src={require('../../tutorial.mp4')} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                        </video>
                                                </div>
                                                <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
                                                        <div>
                                                                <h1 className="fs-3 fw-semibold">{t('callingInfluencer.howBecome')} :</h1>
                                                                <ol className="text-color-blue">
                                                                        {howToBecame.map((how, index) => (
                                                                                <li key={index}>
                                                                                        <span className="fw-bold">{how.step}</span>
                                                                                        <p>{how.desc}</p>
                                                                                </li>
                                                                        ))}
                                                                </ol>
                                                        </div>
                                                        
                                                </div>
                                        </div>
                                </div>
                        </section>
                </>
        );
};

export default CallingDigipreneurComp;
