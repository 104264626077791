import React from "react";
import Testimoni from "../../Components/Testimoni/bdba";
import AwardingComp from "../../Components/AwardingComp";

const Addvertise = () => {
        return (
                <>
                        <main>
                             <AwardingComp />
                             <Testimoni />   
                        </main>
                </>
        );
};

export default Addvertise;
