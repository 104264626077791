import React from "react";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tabs.css'
import { useTranslation } from 'react-i18next';


const TestimonialPage = () => {
        const handleTabChange = (index) => {
                console.log(`Tab switched to index ${index}`);
        };

        const { t } = useTranslation();
        const commonList = t('commonList', { returnObjects: true });
        const digiPreneurList = t('digiPreneurList', { returnObjects: true });

        return (
                <>
                        <section className="mix-bg">
                                <div class="container margin-top margin-bottom text-color-blue">
                                        <Tabs onSelect={handleTabChange}>
                                                <TabList className="d-flex justify-content-center mx-0">
                                                        <div className=" shadow-box rounded-4 ">
                                                                <Tab selectedClassName="custom-active-tab">{t('tabsPolicy.policy')}</Tab>
                                                                <Tab selectedClassName="custom-active-tab">{t('tabsPolicy.digiReward')}</Tab>
                                                        </div>
                                                </TabList>

                                                <TabPanel>
                                                        <div>
                                                                <h1 className="fw-semibold">{t('tabsPolicy.policy')}</h1>
                                                                <p  dangerouslySetInnerHTML={{ __html: t('tabsPolicy.commonPrivacy') }}></p>
                                                                <p>{t('tabsPolicy.kebijakan')}</p>
                                                                <ol>
                                                                        {commonList.map((common, index) => (
                                                                                <li key={index}>{common.list}</li>
                                                                        ))}

                                                                </ol>
                                                        </div>
                                                </TabPanel>
                                                <TabPanel>
                                                        <div>
                                                                <h1 className="fw-semibold">{t('tabsPolicy.digiPreneurReward')}</h1>
                                                                <p  dangerouslySetInnerHTML={{ __html: t('tabsPolicy.digiPrivacy') }}/>
                                                                <p>{t('tabsPolicy.kebijakan')}</p>
                                                                <ol>
                                                                        {digiPreneurList.map((digi, index) => (
                                                                                <li key={index}>{digi.list}</li>  
                                                                        ))}
                                                                </ol>
                                                        </div>
                                                </TabPanel>
                                        </Tabs>
                                </div>
                        </section>
                </>
        );
};

export default TestimonialPage;
