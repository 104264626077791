import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

const ResetPassword = () => {
        const [password, setPassword] = useState('');
        const { id } = useParams();
        const [submissionStatus, setSubmissionStatus] = useState(null);
        const [alertMessage, setAllertMessage] = useState('')
        const [isLoading, setIsLoading] = useState(false);
        const handlePasswordChange = (e) => {
                setPassword(e.target.value);
        };

        const handleResetPassword = async (e) => {
                e.preventDefault();
                setIsLoading(true);
                try {
                        const apiUrl = 'https://app.zeals.asia/api/v1/reset-password';

                        const requestData = {
                                password: password,
                                id: id
                        };

                        const response = await axios.post(apiUrl, requestData);
                        setSubmissionStatus(response.data.status);
                        setAllertMessage(response.data.message)
                } catch (error) {
                        setSubmissionStatus("Error");
                } finally {
                        setIsLoading(false);
                }
        };

        return (
                <section className='pb-first'>
                        <div className='container d-flex justify-content-center align-items-center '>
                                <div className='auth-box p-4 bg-white rounded shadow mt-5'>
                                        <form onSubmit={handleResetPassword}>
                                                <div className="mb-3">
                                                        <label for="password" className="form-label fw-semibold">New Password<span className="text-danger">*</span></label>
                                                        <input type="password" className="form-control" id="password" placeholder='Your new password' required value={password} onChange={handlePasswordChange}/>
                                                </div>
                                                <button type="submit" className="btn btn-landing rounded-pill fw-semibold px-5 w-100 mb-3" disabled={isLoading} >{isLoading ? 'Loading...' : 'Reset Password!'}</button>
                                                {submissionStatus === "success" && (
                                                        <>
                                                                <div class="alert alert-success" role="alert">
                                                                        {alertMessage} 
                                                                </div>
                                                                <Link className="text-decoration-none" to={`/login`}>
                                                                        <p className='text-decoration-none mb-0 text-color-blue text-center fw-semibold'>Login Here!</p>

                                                                </Link>
                                                        </>
                                                        
                                                )}
                                                {submissionStatus === "error" && (
                                                        <div class="alert alert-warning" role="alert">
                                                                 {alertMessage}
                                                        </div>
                                                )}
                                                {submissionStatus === "Error" && (
                                                        <div class="alert alert-danger" role="alert">
                                                                Can not change your password
                                                        </div>
                                                )}
                                        </form>
                                </div>
                        </div>
                        
                </section>
        );
};

export default ResetPassword;
