import React from "react";
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./advertise.css"

const AdvertiseComp = () => { 
        const { t } = useTranslation();
        const advertiserCard = t('advertiserCard', { returnObjects: true });

        return (  
                <>
                        <section className="mix-bg advertise">
                                <div className="container margin-top margin-bottom">
                                        {/* <h1 className="text-center fs-1 fw-bold px-5 text-color-blue">{t('advertiser.text')}</h1> */}
                                        <div className="row mt-5">
                                                {advertiserCard.map((advertise, index) => (
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12 py-5">
                                                                <div key={index} className="center-card-advertise">
                                                                        <img src={require(`../../advertiser/card/${advertise.imageSrc}`)} alt="card-logo" className="icon-card-advertise"/>
                                                                        <div className="card">
                                                                                <div className="card-body py-5 text-color-blue d-flex justify-content-center align-items-center">
                                                                                        <h5 className="fw-bold text-center fs-5 mt-3">{advertise.title}</h5>
                                                                                </div>
                                                                                <div class="overlay">
                                                                                        <div class="text text-center fw-6 px-3">{advertise.desc}</div>
                                                                                </div>
                                                                        </div>
                                                                        
                                                                </div>
                                                        </div>
                                                ))}
                                        </div>
                                </div>
                        </section>
                </>
        );
};

export default AdvertiseComp;
