import React from "react";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faLinkedin, faTiktok, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlayStoreLogo from "../../playstore.png"
import { Link } from "react-router-dom";
import Logo from "../../zeals-logo.png"

const Footer = () => {
        const isSmallScreen = window.innerWidth <= 750;
        
        return (
                <div className="container mb-5">
                        <div className="row">
                                <div className="col-12 col-sm-3">
                                        <h4 className="pb-3 mt-5">Contact</h4>
                                        <div className="txt-footer">
                                                <a href="https://wa.me/+620816779928">
                                                        <div className="media">
                                                                <FontAwesomeIcon icon={faPhone} fixedWidth /> +62 816-779-928
                                                        </div>
                                                </a>
                                                <a href="mailto:hello@zeals.asia">
                                                        <div className="media">
                                                                <FontAwesomeIcon icon={faEnvelope} fixedWidth/> hello@zeals.asia
                                                        </div>
                                                </a>
                                        </div>
                                </div>
                                <div className="col-12 col-sm-3">
                                        <h4 className="pb-3 mt-5">Product</h4>
                                        <div className="txt-footer">
                                                <a href="/influence-connect">InfluenceConnect™</a>
                                                <a href="/zeals-academy">Academy</a>
                                                <a href="/zeals-award">Awarding</a>
                                                {/* <a href="/entertainment">Entertainment</a> */}
                                        </div>
                                </div>
                                <div className="col-12 col-sm-3">
                                        <h4 className="pb-3 mt-5">Privacy</h4>
                                        <div className="txt-footer">

                                                <a href="https://app.zeals.asia/services">Terms & Services</a>
                                                <a href="/policy">Policy Privacy</a>
                                                <a href="https://app.zeals.asia/faq">FAQ</a>
                                        </div>
                                </div>
                                <div className="col-12 col-sm-3">
                                        <h4 className="pb-3 mt-5">About</h4>
                                        <div className="txt-footer">
                                                <a href="/about">About Zeals</a>
                                                <Link to={`https://play.google.com/store/apps/details?id=asia.zeals.mobile.pwa`}a href="">Mobile Apps</Link>
                                        </div>
                                </div>
                        </div>
                        <div className="row mt-5 pb-3">
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6 d-flex justify-content-center flex-column">
                                        <form className="row g-3">
                                                <p className={`m-0 ${isSmallScreen ? 'text-center' : ''}`}>Sign up for our monthly newsletter</p>
                                                <div className="col-lg-8 col-12 col-md-8">
                                                        <input type="Email" className="form-control rounded-pill form-input" id="emailSubscribe" placeholder="Email for subscribe" />
                                                </div>
                                                <div className="col-lg-4 col-12 col-md-4">
                                                        <button type="submit" className="btn btn-landing rounded-pill fw-semibold px-5 w-100 mb-3">Subscribe</button>
                                                </div>
                                        </form>
                                </div>
                                <div className="col-sm-12 col-12 col-md-12 col-lg-6">
                                        <div className="sosmed-data">
                                                <div className="playstore-logo">
                                                        <a className="nav-link" href="https://play.google.com/store/apps/details?id=asia.zeals.mobile.pwa">
                                                                <img src={PlayStoreLogo} alt="Logo" />
                                                        </a>
                                                </div>

                                                <div className="sosmed-group">
                                                        <div className="sosmed-items">
                                                                <Link to={`https://www.facebook.com/ZealsPage`} className="fs-4 px-2 ">
                                                                        <FontAwesomeIcon icon={faFacebook} />
                                                                </Link>
                                                        </div>
                                                        <div className="sosmed-items">
                                                                <Link to={`https://mobile.twitter.com/Zeals_Asia`} className="fs-4 px-2">
                                                                        <FontAwesomeIcon icon={faXTwitter} />
                                                                </Link>
                                                        </div>
                                                        <div className="sosmed-items">
                                                                <Link to={`https://www.linkedin.com/company/zeals-asia/`} className="fs-4 px-2">
                                                                        <FontAwesomeIcon icon={faLinkedin} />
                                                                </Link>
                                                        </div>
                                                        <div className="sosmed-items">
                                                                <Link to={`https://www.instagram.com/zealsasia/`} className="fs-4 px-2">
                                                                        <FontAwesomeIcon icon={faInstagram} />
                                                                </Link>
                                                        </div>
                                                        <div className="sosmed-items">
                                                                <Link to={`http://tiktok.com/@zealsasia`} className="fs-4 px-2">
                                                                        <FontAwesomeIcon icon={faTiktok} />
                                                                </Link>
                                                        </div>     
                                                </div>         
                                        </div>
                                </div>
                        </div>
                        <hr style={{ width: '100%', color: '#313286'}}></hr>
                        <div className="row mt-3">
                                <div className={`col-sm-12 col-lg-6 col-md-6 d-flex align-items-center ${isSmallScreen ? 'justify-content-center mt-3' : 'justify-content-start'} `}>
                                        <img src={Logo} alt="Logo" className="zeals-logo-footer" />
                                </div>
                                <div className={`col-sm-12 col-lg-6 col-md-6 d-flex align-items-center ${isSmallScreen ? 'justify-content-center' : 'justify-content-end'} `}>
                                        © 2023 Zeals Digital Asia 
                                </div>
                        </div>
                </div>
        );
};

export default Footer;
