// import axios from "axios";
import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../data.json";
import Form from "../../Components/Form"
import Landing from "../../Components/Landing"
import Testimoni from "../../Components/Testimoni";

const LandingPage = () => {
  return (
    <>
      <main>
        <Landing />
        {/* <Testimoni /> */}
        {/* <Form /> */}
      </main>
    </>
  );
};

export default LandingPage;
