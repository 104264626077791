import React, {useEffect, useState} from 'react';
import axios from 'axios'
import { Link } from "react-router-dom";

export const Axios = axios.create({
        baseURL: 'https://app.zeals.asia/api/v1/',
});

const DashboardPage = () => {
        const [scoringData, setScoringData] = useState([]);
        const [detailData, setDetailData] = useState({});

        useEffect(() => {
                async function getScoring(){
                        const loginToken = localStorage.getItem('loginToken');
                        Axios.defaults.headers.common['Authorization'] = 'Bearer '+ loginToken;
                        try {
                                const response = await Axios.get('get-scoring');
                                setScoringData(response.data.score);
                                const allScore = response.data.score;
                                const allDetailData = allScore.map((info) => {
                                        const data = JSON.parse(info.score);
                                        return data;
                                });
                                setDetailData(allDetailData);
                        } catch (error) {
                                console.error('Error fetching scoring data:', error);
                        }    
                }
                getScoring();
        },[]);

        const calculateTotalPointsAndDivide = (data, categoryName) => {
                const category = data[categoryName];
                if (category) {
                        const aspects = Object.keys(category).filter((aspect) => aspect !== 'juryNotes').map((aspect) => category[aspect]);
                        const totalWithoutJuryNotes = aspects.reduce((acc, value) => acc + value, 0);
                        const totalAspectsWithoutJuryNotes = Object.keys(category).length - 1;
                        const totalAfterDivision = totalWithoutJuryNotes / totalAspectsWithoutJuryNotes;
                        return totalAfterDivision;
                }
                return 0;
        };

        const categoryMapping = {
                digitalStrategy: 'digitalStrategy',
                websiteInnovation: 'websiteInnovation',
                eCommerce: 'eCommerce',
                sosMed: 'sosMed',
                content: 'content',
                customer: 'customer',
                socialImpact: 'socialImpact',
                resilience: 'resilience',
                inovation: 'inovation',
                surprise: 'surprise',
        };


        const totalPointsArray = [];

        if (detailData && detailData.length > 0) {
                detailData.forEach((data) => {
                        const totalPointsForCategory = Object.keys(categoryMapping).reduce((acc, key) => {
                                const totalPointsForCategoryAndData = calculateTotalPointsAndDivide(data, key);
                                return acc + totalPointsForCategoryAndData;
                        }, 0);
                        totalPointsArray.push(totalPointsForCategory);
                });
        }

        return(
                <>
                        <section>
                                <div className='container w-75 mx-auto mt-5'>
                                        <div className="h4 pb-2 mb-4 border-bottom border-dark text-center text-uppercase">
                                                participant list & score detail
                                        </div>
                                        <table className="table">
                                                <thead>
                                                        <tr>
                                                                <th scope="col">No.</th>
                                                                <th scope="col">Company Name</th>
                                                                <th scope="col" className='text-center'>Award Nomination</th>
                                                                <th scope="col" className='text-center'>Total Score</th>
                                                                <th scope="col" className='text-center'>Action</th>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                        {scoringData.map((item, index) => (
                                                                <tr key={index}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{item.company_name}</td>
                                                                        <td className='text-center'>{item.award_nomination}</td>
                                                                        <td className='text-center'>
                                                                                { totalPointsArray[index] }
                                                                        </td>
                                                                        <td className='d-flex justify-content-center align-items-center'>
                                                                                <Link to={`detail/${item.id}`} className="btn btn-landing rounded-pill fw-semibold px-5 mb-3 ">
                                                                                        Detail
                                                                                </Link>
                                                                        </td>
                                                                </tr>
                                                        ))}
                                                </tbody>
                                        </table>
                                
                                </div> 
                        </section>
                </>
        );
}

export default DashboardPage