import React from 'react';

const NotFoundPage = () => {
        return (
                <section>
                        <div className='mix-bg margin-top margin-bottom'>
                                <h1 className='fs-bold text-center text-color-blue'>404 - Page Not Found</h1>
                                <p className='fe-semibold text-center text-color-blue'>The page you are looking for does not exist.</p>
                        </div>
                </section>
        );
};

export default NotFoundPage;
