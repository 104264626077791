import React, { useEffect, useContext, useState  } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {UserContext} from './Context/UserContext';
import Swal from "sweetalert2"; 
import LandingPage from "./Pages/Landing/LandingPage";
import InfluenceConnect from "./Pages/InfluenceConnect/InfluenceConnect";
import AcademyZeals from "./Pages/Academy/Academy";
import CallingDigipreneur from "./Pages/CallingDigipreneur/Calling";
import SignUp from "./Pages/Auth/signup";
import PenilaianJuri from "./Pages/Penilaian/penilaian";
import Login from "./Pages/Auth/login";
import Contact from "./Pages/Contact/Contact";
import Testimoni from "./Pages/TestimonialPage/TestimonialPage";
import AwardingPage from "./Pages/Awarding/AwardingPage";
import About from "./Pages/About/AboutPage";
import Navbar from "./Components/Navbar";
import JuriNavbar from "./Components/JuriNavbar";
import Footer from "./Components/Footer";
import Privacy from "./Pages/privacyPolicy/privacy";
import NotFoundPage from "./Pages/404Page/404Page";
import "./App.css"
import "../src/Components/Components.css";
import Whatsapp from "./whatsapp.png"
import ReactGA from 'react-ga4';
import DashboardPage from "./Pages/Dashboard";
import ResetPassword from "./Pages/ResetPassword";
import DetailPage from "./Pages/Detail";
import ListJudger from "./Pages/ListJudger";
import ListCompany from "./Pages/Company";
import DashboardCompany from "./Pages/DashboardAdmin";
import DetailAdmin from "./Pages/DetailAdmin";
const TRACKING_ID = "G-8KCGWSM3GZ";
ReactGA.initialize(TRACKING_ID);

const App = () => {
        useEffect(() => {
                ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        }, []);
        const {user} = useContext(UserContext); 
        const {admin} = useContext(UserContext); 
        const [userDataAvailable, setUserDataAvailable] = useState(false);
        const [loading, setLoading] = useState(true);

        useEffect(() => {
                setTimeout(() => {
                        if (user) {
                                setUserDataAvailable(true);
                        }
                        setLoading(false);

                }, 2000);
        }, [user]);

        useEffect(() => {
            const flavor = process.env.REACT_APP_FLAVOR
            if(flavor === 'ZEALS'){
                document.title = 'Zeals Asia | #1 Affiliate & Digital Marketing Platform'
            }else if(flavor === 'KAILS'){
                document.title = 'KAI Lifestyle | Affiliate Platform by KAI'
            }
        })

        if (loading) {
                return <div className="loading-screen">Loading....</div>;
        }

        if (admin) {
                return (
                        <BrowserRouter>
                                <header>
                                        <JuriNavbar />
                                </header>
                                <main>
                                        <Routes>
                                                <>
                                                        <Route>
                                                                <Route path="/" element={<DashboardCompany />} />
                                                                <Route path="/list-judger" element={<ListJudger />} />
                                                                <Route path="/list-company" element={<ListCompany />} />
                                                                <Route path="/detail-score/:id" element={<DetailAdmin />} />
                                                                <Route path="*" element={<NotFoundPage />} />
                                                        </Route>
                                                </>
                                        </Routes>
                                </main>
                                <footer className="mt-5">
                                        <Footer />
                                </footer>
                        </BrowserRouter>
                );
        }

        return (
                <BrowserRouter>
                        <header>
                                {user ? <JuriNavbar /> : <Navbar />}
                        </header>
                        <main>
                                <Routes>
                                        {user ? (
                                                <>
                                                        <Route path="/scoring-form" element={<PenilaianJuri user={user} />} />
                                                        <Route path="/" element={<DashboardPage user={user} />} />
                                                        <Route path="*" element={<NotFoundPage />} />
                                                        <Route path="/detail/:id" element={<DetailPage />} />
                                                </>
                                        ) : (
                                                <>
                                                        <Route path="/" element={<LandingPage />} />
                                                        <Route path="/influence-connect" element={<InfluenceConnect />} />
                                                        <Route path="/contact" element={<Contact />} />
                                                        <Route path="/zeals-academy" element={<AcademyZeals />} />
                                                        <Route path="/calling-all-digipreneur" element={<CallingDigipreneur />} />
                                                        <Route path="/zeals-award" element={<AwardingPage />} />
                                                        <Route path="/testimoni" element={<Testimoni />} />
                                                        <Route path="/about" element={<About />} />
                                                        <Route path="/policy" element={<Privacy />} />
                                                        <Route path="/signup-judger" element={<SignUp />} />
                                                        <Route path="/login" element={<Login />} />
                                                        <Route path="/reset-password/:id" element={<ResetPassword />} />
                                                        <Route path="*" element={<NotFoundPage />} />
                                                </>
                                        )}
                                </Routes>
                                <div class="whatsapp-float">
                                        <a href="https://wa.me/+62816779928">
                                                <img src={Whatsapp} alt="WhatsApp" />
                                        </a>
                                </div>
                        </main>
                        <footer className="mt-5">
                                <Footer />
                        </footer>
                </BrowserRouter>
        );
};

export default App;
