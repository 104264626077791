import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import enTranslations from './translations/en.json';
import idTranslations from './translations/id.json';

i18n
        .use(initReactI18next)
        .init({
                resources: {
                        en: { translation: enTranslations },
                        id: { translation: idTranslations },
                },
                lng: 'en',
                fallbackLng: 'en',
        });

export default i18n;
