import React from "react";
import AboutComp from "../../Components/AboutComp"
import Testimoni from "../../Components/Testimoni";

const About = () => {
        return (
                <>
                        <main>
                                <AboutComp />
                                {/* <Testimoni /> */}
                        </main>
                </>
        );
};

export default About;
