import React, { useState } from "react";
import axios from 'axios'
import Swal from "sweetalert2";

export const Axios = axios.create({
        baseURL: 'https://app.zeals.asia/api/v1/',
});

const PenilaianJuri = ({user}) => {
        const [submissionStatus, setSubmissionStatus] = useState(null);
        const [scores, setScores] = useState({
                digitalStrategy: {
                        clarity: 0,
                        alignment: 0,
                        roadmap: 0,
                        juryNotes: '',
                },
                websiteInnovation: {
                        uiDesign: 0,
                        functionality: 0,
                        impact: 0,
                        juryNotes: '',
                },
                eCommerce: {
                        e2e: 0,
                        demonstrated: 0,
                        Streamlining: 0,
                        juryNotes: '',
                },
                sosMed: {
                        Quality: 0,
                        Community: 0,
                        impact: 0,
                        juryNotes: '',
                },
                content: {
                        Innovation: 0,
                        Effectiveness: 0,
                        Brand: 0,
                        juryNotes: '',
                },
                customer: {
                        Personalization: 0,
                        Quality: 0,
                        Use: 0,
                        juryNotes: '',
                },
                socialImpact: {
                        Demonstrated: 0,
                        ethical: 0,
                        Measurable: 0,
                        juryNotes: '',
                },
                resilience: {
                        ability: 0,
                        Endurance: 0,
                        Experimental: 0,
                        juryNotes: '',
                },
                inovation: {
                        Demonstrated: 0,
                        Creation: 0,
                        juryNotes: '',
                },
                surprise: {
                        unique: 0,
                        juryNotes: '',
                },
        });
        
        const [company_id, setCompanyName] = useState("");
        const [award_nomination, setAwardNomination] = useState("");

        const handleScoreChange = (category, criteria, score) => {
                setScores((prevScores) => ({
                        ...prevScores,
                        [category]: {
                                ...prevScores[category],
                                [criteria]: score,
                        },
                }));
        };
            
        const handleNotesChange = (category, event) => {
                const notes = event.target.value;
                setScores((prevScores) => ({
                        ...prevScores,
                        [category]: {
                                ...prevScores[category],
                                juryNotes: notes,
                        },
                }));
        };
            
        const handleCompanyNameChange = (event) => {
                const selectedCompanyId = event.target.value;
                const selectedCompany = companyList.find((company) =>  company.id === parseInt(selectedCompanyId));
                if (selectedCompanyId) {
                        Swal.fire({
                                title: 'Confirm',
                                text: `Are you sure you want to select ${selectedCompany ? selectedCompany.company_name : 'this company'}?`,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Yes',
                        }).then((result) => {
                                if (result.isConfirmed) {
                                        setCompanyName(selectedCompanyId);
                                        setAwardNomination(selectedCompany.award_nomination)
                                }
                        });
                } else {
                        setCompanyName(""); 
                }
        };
            
        const handleSubmit = async (event) => {
                event.preventDefault();
                try {
                        Swal.showLoading();
                        await Axios.post('store-score', {
                                user,
                                scores,
                                company_id
                        });

                        Swal.fire({
                                icon: "success",
                                title: "Score added successfully!!",
                                text: "Thankyou for submitting",
                        });
                        setSubmissionStatus("Success");
                } catch (error) {
                        setSubmissionStatus("Error");
                }
        };

        const [companyList, setCompanyList] = useState([]);
        React.useEffect(() => {
                const fetchCompanyList = async () => {
                        const loginToken = localStorage.getItem('loginToken');
                        Axios.defaults.headers.common['Authorization'] = 'Bearer '+ loginToken;
                        try {
                                const response = await Axios.get('get-company');
                                setCompanyList(response.data.company);
                        } catch (error) {
                                console.error('Error fetching company list:', error);
                        }
                };

                fetchCompanyList();
        }, []);
            
        return (
                <div className="container">
                        <form onSubmit={handleSubmit} className="w-75 mx-auto mt-5">
                                <div className="mb-3">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                Company Name
                                        </div>
                                        <select
                                                className="form-select"
                                                id="company_id"
                                                name="company_id"
                                                onChange={handleCompanyNameChange}
                                                required
                                        >
                                                <option value="">Select a company</option>
                                                {companyList.map((company, index) => (
                                                        <option key={index} value={company.id}>
                                                                {company.company_name}
                                                        </option>
                                                ))}
                                                <label className="fw-semibold">Excellent</label>
                                        </select>
                                </div>
                                <div className="mb-3">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                Award Nomination
                                        </div>
                                        <label className="fw-semibold h5">{ award_nomination ? award_nomination : ""}</label>
                                </div>
                                <div className="form-group">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                Digital Strategy
                                        </div>
                                        <div className="mt-4">
                                                <label>Clarity and innovation of strategy</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="digitalStrategy.clarity"
                                                                                id={`digitalStrategy.clarity${score}`}
                                                                                value={score}
                                                                                checked={scores.digitalStrategy.clarity === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("digitalStrategy", "clarity", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`digitalStrategy.clarity${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>
                                        <div className="mt-4">
                                                <label>Alignment with business objectivesy</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="digitalStrategy.alignment"
                                                                                id={`digitalStrategy.alignment${score}`}
                                                                                value={score}
                                                                                checked={scores.digitalStrategy.alignment === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("digitalStrategy", "alignment", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`digitalStrategy.alignment${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <div className="mt-4">
                                                <label>Demonstrated roadmap for transformation</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="digitalStrategy.roadmap"
                                                                                id={`digitalStrategy.roadmap${score}`}
                                                                                value={score}
                                                                                checked={scores.digitalStrategy.roadmap === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("digitalStrategy", "roadmap", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`digitalStrategy.alignment${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>
                                        
                                        <label className="mt-3">Jury's notes</label>
                                        <textarea
                                                className="form-control mt-2"
                                                rows="4"
                                                value={scores.digitalStrategy.juryNotes}
                                                onChange={(event) => handleNotesChange('digitalStrategy', event)}
                                        />
                                </div>
                                
                                <div className="form-group">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                Website Innovation
                                        </div>
                                        <div>
                                                <label >UI Design and user experience</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="websiteInnovation.uiDesign"
                                                                                id={`websiteInnovation.uiDesign${score}`}
                                                                                value={score}
                                                                                checked={scores.websiteInnovation.uiDesign === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("websiteInnovation", "uiDesign", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`websiteInnovation.uiDesign${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>
                                        <div className="mt-4">
                                                <label>Website functionality</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="websiteInnovation.functionality"
                                                                                id={`websiteInnovation.functionality${score}`}
                                                                                value={score}
                                                                                checked={scores.websiteInnovation.functionality === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("websiteInnovation", "functionality", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`websiteInnovation.functionality${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <div className="mt-4">
                                                <label>Demonstrated impact on brand growth and sales</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="websiteInnovation.impact"
                                                                                id={`websiteInnovation.impact${score}`}
                                                                                value={score}
                                                                                checked={scores.websiteInnovation.impact === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("websiteInnovation", "impact", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`websiteInnovation.impact${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        
                                        
                                        <label className="mt-3">Jury's notes</label>
                                        <textarea
                                                className="form-control mt-2"
                                                rows="4"
                                                value={scores.websiteInnovation.juryNotes}
                                                onChange={(event) => handleNotesChange('websiteInnovation', event)}
                                        />
                                </div>

                                <div className="form-group">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                E-commerce Integration
                                        </div>
                                        <div className="mt-4">
                                                <label>End-to-end Integration (including offline and online)</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="eCommerce.e2e"
                                                                                id={`eCommerce.e2e${score}`}
                                                                                value={score}
                                                                                checked={scores.eCommerce.e2e === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("eCommerce", "e2e", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`eCommerce.e2e${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>
                                        <div className="mt-4">
                                                <label>Demonstrated incorporation of emerging trends and technologies to maintain a competitive edge.</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="eCommerce.demonstrated"
                                                                                id={`eCommerce.demonstrated${score}`}
                                                                                value={score}
                                                                                checked={scores.eCommerce.demonstrated === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("eCommerce", "demonstrated", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`eCommerce.demonstrated${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <div className="mt-4">
                                                <label>Streamlining of the purchasing proces</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="eCommerce.Streamlining"
                                                                                id={`eCommerce.Streamlining${score}`}
                                                                                value={score}
                                                                                checked={scores.eCommerce.Streamlining === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("eCommerce", "Streamlining", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`eCommerce.Streamlining${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <label className="mt-3">Jury's notes</label>
                                        <textarea
                                                className="form-control mt-2"
                                                rows="4"
                                                value={scores.eCommerce.juryNotes}
                                                onChange={(event) => handleNotesChange('eCommerce', event)}
                                        />
                                </div>

                                <div className="form-group">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                Social Media and Community Engagement
                                        </div>
                                        <div className="mt-4">
                                                <label>Quality and creativity of social media conten</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="sosMed.Quality"
                                                                                id={`sosMed.Quality${score}`}
                                                                                value={score}
                                                                                checked={scores.sosMed.Quality === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("sosMed", "Quality", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`sosMed.Quality${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>
                                        <div className="mt-4">
                                                <label>Community growth and engagement</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="sosMed.Community"
                                                                                id={`sosMed.Community${score}`}
                                                                                value={score}
                                                                                checked={scores.sosMed.Community === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("sosMed", "Community", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`sosMed.Community${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <div className="mt-4">
                                                <label>Two-way communication and customer relationships</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="sosMed.impact"
                                                                                id={`sosMed.impact${score}`}
                                                                                value={score}
                                                                                checked={scores.sosMed.impact === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("sosMed", "impact", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`sosMed.impact${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <label className="mt-3">Jury's notes</label>
                                        <textarea
                                                className="form-control mt-2"
                                                rows="4"
                                                value={scores.sosMed.juryNotes}
                                                onChange={(event) => handleNotesChange('sosMed', event)}
                                        />
                                </div>

                                <div className="form-group">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                Content Innovation and Online Marketing
                                        </div>
                                        <div className="mt-4">
                                                <label>Innovation and quality of digital content</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="content.Innovation"
                                                                                id={`content.Innovation${score}`}
                                                                                value={score}
                                                                                checked={scores.content.Innovation === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("content", "Innovation", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`content.Innovation${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>
                                        <div className="mt-4">
                                                <label>Effectiveness of online marketing strategies</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="content.Effectiveness"
                                                                                id={`content.Effectiveness${score}`}
                                                                                value={score}
                                                                                checked={scores.content.Effectiveness === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("content", "Effectiveness", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`content.Effectiveness${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <div className="mt-4">
                                                <label>Brand message consistency and resonance</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="content.Brand"
                                                                                id={`content.Brand${score}`}
                                                                                value={score}
                                                                                checked={scores.content.Brand === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("content", "Brand", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`content.Brand${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <label className="mt-3">Jury's notes</label>
                                        <textarea
                                                className="form-control mt-2"
                                                rows="4"
                                                value={scores.content.juryNotes}
                                                onChange={(event) => handleNotesChange('content', event)}
                                        />
                                </div>

                                <div className="form-group">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                Customer-Centric and Analytics Digital Services
                                        </div>
                                        <div className="mt-4">
                                                <label>Personalization and customization</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="customer.Personalization"
                                                                                id={`customer.Personalization${score}`}
                                                                                value={score}
                                                                                checked={scores.customer.Personalization === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("customer", "Personalization", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`customer.Personalization${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>
                                        <div className="mt-4">
                                                <label>Quality of responsive customer support</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="customer.Quality"
                                                                                id={`customer.Quality${score}`}
                                                                                value={score}
                                                                                checked={scores.customer.Quality === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("customer", "Quality", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`customer.Quality${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <div className="mt-4">
                                                <label>Use of data and analytics for improvements</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="customer.Use"
                                                                                id={`customer.Use${score}`}
                                                                                value={score}
                                                                                checked={scores.customer.Use === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("customer", "Use", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`customer.Use${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <label className="mt-3">Jury's notes</label>
                                        <textarea
                                                className="form-control mt-2"
                                                rows="4"
                                                value={scores.customer.juryNotes}
                                                onChange={(event) => handleNotesChange('customer', event)}
                                        />
                                </div>

                                <div className="form-group">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                Social Impact Responsibility
                                        </div>
                                        <div className="mt-4">
                                                <label>Demonstrated initiatives for social and environmental impact</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="socialImpact.Demonstrated"
                                                                                id={`socialImpact.Demonstrated${score}`}
                                                                                value={score}
                                                                                checked={scores.socialImpact.Demonstrated === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("socialImpact", "Demonstrated", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`socialImpact.Demonstrated${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>
                                        <div className="mt-4">
                                                <label>Demonstrated ethical digital practices</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="socialImpact.ethical"
                                                                                id={`socialImpact.ethical${score}`}
                                                                                value={score}
                                                                                checked={scores.socialImpact.ethical === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("socialImpact", "ethical", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`socialImpact.ethical${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <div className="mt-4">
                                                <label>Measurable contributions to social causes, environmental improvements, or community supports</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="socialImpact.Measurable"
                                                                                id={`socialImpact.Measurable${score}`}
                                                                                value={score}
                                                                                checked={scores.socialImpact.Measurable === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("socialImpact", "Measurable", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`socialImpact.Measurable${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <label className="mt-3">Jury's notes</label>
                                        <textarea
                                                className="form-control mt-2"
                                                rows="4"
                                                value={scores.socialImpact.juryNotes}
                                                onChange={(event) => handleNotesChange('socialImpact', event)}
                                        />
                                </div>

                                <div className="form-group">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                Resilience and Endurance
                                        </div>
                                        <div className="mt-4">
                                                <label>The ability to bounce back and endure in the face of challenges</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="resilience.ability"
                                                                                id={`resilience.ability${score}`}
                                                                                value={score}
                                                                                checked={scores.resilience.ability === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("resilience", "ability", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`resilience.ability${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>
                                        <div className="mt-4">
                                                <label>Endurance in marketing efforts/social media despite obstacles</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="resilience.Endurance"
                                                                                id={`resilience.Endurance${score}`}
                                                                                value={score}
                                                                                checked={scores.resilience.Endurance === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("resilience", "Endurance", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`resilience.Endurance${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <div className="mt-4">
                                                <label>Experimental attitude (Let's try something different)</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="resilience.Experimental"
                                                                                id={`resilience.Experimental${score}`}
                                                                                value={score}
                                                                                checked={scores.resilience.Experimental === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("resilience", "Experimental", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`resilience.Experimental${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <label className="mt-3">Jury's notes</label>
                                        <textarea
                                                className="form-control mt-2"
                                                rows="4"
                                                value={scores.resilience.juryNotes}
                                                onChange={(event) => handleNotesChange('resilience', event)}
                                        />
                                </div>

                                <div className="form-group">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                Innovation and Creativity
                                        </div>
                                        <div className="mt-4">
                                                <label>Demonstrated ability to innovate and adapt to changing digital landscapes</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="inovation.Demonstrated"
                                                                                id={`inovation.Demonstrated${score}`}
                                                                                value={score}
                                                                                checked={scores.inovation.Demonstrated === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("inovation", "Demonstrated", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`inovation.Demonstrated${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>
                                        <div className="mt-4">
                                                <label>Creation of original and compelling content or campaigns that stand out for their uniqueness and effectiveness</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="inovation.Creation"
                                                                                id={`inovation.Creation${score}`}
                                                                                value={score}
                                                                                checked={scores.inovation.Creation === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("inovation", "Creation", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`inovation.Creation${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <label className="mt-3">Jury's notes</label>
                                        <textarea
                                                className="form-control mt-2"
                                                rows="4"
                                                value={scores.inovation.juryNotes}
                                                onChange={(event) => handleNotesChange('inovation', event)}
                                        />
                                </div>

                                <div className="form-group">
                                        <div class="h4 pb-2 mb-4 border-bottom border-dark">
                                                Surprise Factor
                                        </div>
                                        <div className="mt-4">
                                                <label>A unique and unexpected element that sets the entry apart</label>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                        <label className="fw-semibold">Poor</label>
                                                        {[0, 1, 2, 3, 4].map((score) => (
                                                                <div key={score} className="form-check form-check-inline">
                                                                        <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="surprise.unique"
                                                                                id={`surprise.unique${score}`}
                                                                                value={score}
                                                                                checked={scores.surprise.unique === score}
                                                                                onChange={() =>
                                                                                handleScoreChange("surprise", "unique", score)
                                                                                }
                                                                        />
                                                                        <label
                                                                                className="form-check-label"
                                                                                htmlFor={`surprise.unique${score}`}
                                                                        >
                                                                                {score}
                                                                        </label>
                                                                </div>
                                                        ))}
                                                        <label className="fw-semibold">Excellent</label>
                                                </div>
                                        </div>

                                        <label className="mt-3">Jury's notes</label>
                                        <textarea
                                                className="form-control mt-2"
                                                rows="4"
                                                value={scores.surprise.juryNotes}
                                                onChange={(event) => handleNotesChange('surprise', event)}
                                        />
                                </div>
                                <button type="submit" className="btn btn-landing rounded-pill fw-semibold px-5 w-100 mb-3 mt-3">Submit</button>

                                {submissionStatus === "Success" && (
                                        <div class="alert alert-success mt-3" role="alert">
                                                Score Stored!
                                        </div>
                                )}
                                {submissionStatus === "Error" && (
                                        <div class="alert alert-danger mt-5" role="alert">
                                                Can not store your score
                                        </div>
                                )}
                                
                        </form>
                </div>
        );
};

export default PenilaianJuri;
