import React, { useEffect } from "react";
import "./academy.css";
import { gsap } from "gsap";
import Header from "../../academy/zeals-academy.png"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const AcademyZeals = () => {  
        const { t } = useTranslation();
        const academyCard = t('academyCard', { returnObjects: true });
        const academyPhotos = t('academyPhotos', { returnObjects: true });
        const academyTiktoks = t('academytiktok', { returnObjects: true });
        const tiktokLivestreaming = t('tiktokLivestreaming', { returnObjects: true });
        const academyDesc = t('academyDesc', { returnObjects: true });

        const animateFadeIn = (element, direction) => {
                let animationProps = { opacity: 0 };
                if (direction === 'left') {
                        animationProps.x = 60;
                } else {
                        animationProps.x = -60;
                }

                gsap.fromTo(
                        element,
                        animationProps,
                        { opacity: 1, x: 0, y: 0, duration: 1, ease: 'power1.out' }
                );
        };

        const isSmallScreen = window.innerWidth <= 750;

        useEffect(() => {
                const columns = document.querySelectorAll('.fade-in-column');
                columns.forEach((col, index) => {
                        let direction = 'left';
                        if (index % 2 === 0) {
                                direction = 'right';
                        }
                        animateFadeIn(col, direction);
                });
        }, []);
        return (  
                <>
                        <section className="pb-first">
                                <article>
                                        <div className="container">
                                                <div className="row margin-top mb-5">
                                                        <div className="col-md-12 col-lg-6 col-sm-12 d-flex align-items-center fade-in-column">
                                                                <div>
                                                                        <h1 className="fs-0 text-color-blue">{t('content.academy.header')}</h1>
                                                                        <p className="text-muted fs-5 sub-title">{t('content.academy.desc')}</p>
                                                                </div>
                                                        </div>
                                                        <div className="col-md-12 col-lg-6 col-sm-12 d-flex justify-content-center align-items-center fade-in-column">
                                                                <img src={Header} alt="hexagon logo" className="hexagon-logo"/>
                                                        </div>
                                                </div>

                                        </div>
                                </article>
                        </section>
                        <section>
                                <div className="container">
                                        <p className="fs-4 text-center text-color-blue margin-top">{t('content.academy.paragraph')}</p> 
                                </div>
                        </section>
                        <section >
                                <div className="container margin-top">
                                        <div className="row">
                                                {academyCard.map((academy, index) => (
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 py-5">
                                                                <div key={index} className="center-card shadow-box rounded-4 ">
                                                                        <img src={require(`../../academy/card/${academy.imageSrc}`)} alt="card-logo" className="icon-card-academy"/>
                                                                        <div className="card border-0">
                                                                                <div className="card-body py-5">
                                                                                        <h5 className="fw-bold text-center fs-3 mt-4 text-color-blue">{academy.title}</h5>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                ))}
                                        </div>
                                </div>
                        </section>
                        <section className="mix-bg">
                                <div className="container margin-top">
                                        <h1 className="fs-1 fw-bold text-center text-color-blue">Academy - Direct to Customer</h1>
                                        <div className="row margin-top">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-center mb-5 position-relative">
                                                        <div id="academyPhotos" className="carousel slide rounded-4 shadow-box" data-bs-ride="carousel">
                                                                <div className="carousel-inner rounded-4">
                                                                        {academyPhotos.map((academyPhoto, index) => (
                                                                                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index} data-bs-interval="2000">
                                                                                        <img src={require(`../../academy/Photo/${academyPhoto.photos}`)} alt="card-logo" className="d-block w-100"/>
                                                                                </div>
                                                                        ))}
                                                                </div>
                                                                <button className="carousel-control-prev" type="button" data-bs-target="#academyPhotos" data-bs-slide="prev">
                                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                        <span className="visually-hidden">Previous</span>
                                                                </button>
                                                                        <button className="carousel-control-next" type="button" data-bs-target="#academyPhotos" data-bs-slide="next">
                                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                        <span className="visually-hidden">Next</span>
                                                                </button>
                                                        </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-center">
                                                        <div className="text-color-blue">
                                                                {academyDesc.map((academyDes, index) => (
                                                                        <p className="fw-semobold m-0 fs-4" key={index}>{index + 1}.{academyDes.desc}</p>
                                                                ))}      
                                                        </div>
                                                </div>
                                        </div>
                                        <div className="row margin-top">
                                                {academyTiktoks.map((academyTiktok, index) => (
                                                        <div className="col-lg-4 col-md-4 col-sm-12 col-12" key={index}>
                                                                <Link to={`${academyTiktok.link}`} className="d-flex justify-content-center align-items-center tiktok-photos">
                                                                        <img src={require(`../../academy/tiktok/${academyTiktok.photos}`)} alt="tiktok" className="shadow-box w-75 tiktok-image mb-5"/>
                                                                </Link>
                                                        </div>
                                                ))}
                                                <h1 className="fs-1 fw-bold text-center text-color-blue">TikTok Live Streaming</h1>
                                                {tiktokLivestreaming.map((academyTiktok, index) => (
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 margin-top " key={index}>
                                                                <Link to={`${academyTiktok.link}`} className="d-flex justify-content-center align-items-center tiktok-photos">
                                                                        <img src={require(`../../academy/tiktok/${academyTiktok.photos}`)} alt="tiktok" className={`shadow-box tiktok-image mb-5 ${isSmallScreen ? 'w-75' : 'w-50'}`} />
                                                                </Link>
                                                        </div>
                                                ))}
                                        </div>
                                </div>
                        </section>
                </>
        );
};

export default AcademyZeals;
