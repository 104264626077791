import React, { useState, useEffect } from "react";
import Data from "../../../data.json";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const LogoSlider = () => {
        const [settings, setSettings] = useState({
                dots: false,
                infinite: true,
                slidesToShow: 4,
                autoplay: true,
                speed: 2000,
                autoplaySpeed: 200,
                arrows: false,
        });

        useEffect(() => {

                const updateSlidesToShow = () => {
                        if (window.innerWidth <= 500) {
                                setSettings({ ...settings, slidesToShow: 3 });
                        } else {
                                setSettings({ ...settings, slidesToShow: 5 }); // Default for non-mobile screens
                        }
                };
                
                updateSlidesToShow();

                // Add a listener for window resize events
                window.addEventListener('resize', updateSlidesToShow);

                // Clean up the listener on component unmount
                return () => {
                        window.removeEventListener('resize', updateSlidesToShow);
                };
                // setSettings({ ...settings, autoplay: true });
        }, []);
        const logos = [
                process.env.PUBLIC_URL + '/img/clients/logo-01.png',
                process.env.PUBLIC_URL + '/img/clients/logo-02.png',
                process.env.PUBLIC_URL + '/img/clients/logo-03.png',
                process.env.PUBLIC_URL + '/img/clients/logo-04.png',
                process.env.PUBLIC_URL + '/img/clients/logo-07.png',
                process.env.PUBLIC_URL + '/img/clients/logo-09.png',
                process.env.PUBLIC_URL + '/img/clients/logo-10.png',
                process.env.PUBLIC_URL + '/img/BDBA/alfa.png',
                process.env.PUBLIC_URL + '/img/BDBA/astra.png',
                process.env.PUBLIC_URL + '/img/BDBA/blu.png',
                process.env.PUBLIC_URL + '/img/BDBA/eiger.png',
                process.env.PUBLIC_URL + '/img/BDBA/first.png',
                process.env.PUBLIC_URL + '/img/BDBA/hatari.png',
                process.env.PUBLIC_URL + '/img/BDBA/mandaya.png',
                process.env.PUBLIC_URL + '/img/BDBA/sasa.png',
                process.env.PUBLIC_URL + '/img/BDBA/white.png',
                process.env.PUBLIC_URL + '/img/campus/binus.png',
                process.env.PUBLIC_URL + '/img/campus/akpar.png',
                process.env.PUBLIC_URL + '/img/campus/prasmul.png',
                process.env.PUBLIC_URL + '/img/campus/wacana.png',
                process.env.PUBLIC_URL + '/img/campus/mara.png',
                process.env.PUBLIC_URL + '/img/clients/medcom.png',
                process.env.PUBLIC_URL + '/img/clients/nutrimax.png',
        ];
        return (
                <div className="mb-5">
                        <p className="text-center fs-3 text-color-blue fw-bold">Our Partners and Clients</p>
                        <Slider {...settings}>
                                {logos.map((image, index) => (
                                        <div key={index} className="container mt-4">
                                                <img src={image} alt='logo' className="client-logo"/>
                                        </div>
                                ))}
                        </Slider>
                </div>
        );
};

export default LogoSlider;
