import React from "react";
import AcademyZeals from "../../Components/AcademyZeals"
import Testimoni from "../../Components/Testimoni";

const Academy = () => {
        return (
                <>
                        <main>
                                <AcademyZeals />
                                {/* <Testimoni /> */}
                        </main>
                </>
        );
};

export default Academy;
