import React from "react";
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Dummy from "../../dummy-business.jpg"

const TestimoniComp = () => { 

        const { t } = useTranslation();
        const testimonials = t('testimonialcard', { returnObjects: true });
        return (  
                <>
                        <section>
                                <div className="container margin-top">
                                        <h3 className="text-center fs-1 mb-5 text-color-blue">Check our product testimonial</h3>
                                        <hr style={{ color: '#313286'}} ></hr>
                                        <div className="row margin-top">
                                                {testimonials.map((testimonial, index) => (
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <div key={index} className="card custom-card mb-3 shadow-box border-0" >
                                                                        <img src={Dummy} className="card-img-top" alt="..." />
                                                                        <div className="card-body">
                                                                                <p className="card-text">{testimonial.content}</p>
                                                                                <h5 className="card-title mb-0">{testimonial.name}</h5>
                                                                                <span className="text-muted">{testimonial.position}</span>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                ))}
                                        </div>
                                </div>
                        </section>
                </>
        );
};

export default TestimoniComp;
